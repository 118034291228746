import { GeoJsonLayer } from "@deck.gl/layers";
import chroma from "chroma-js";
import { TIMELOG_COLOR_SCALE } from "../utils";
import { convertToGeoJSONPoints } from "../utils/PointGeoJSONUtils";

const OUTLIER_COLOR: [number, number, number] = [255, 0, 255]




export default class MeasurementPointsLayer extends GeoJsonLayer<unknown>{
    constructor(points:number[][]) {
        const colors = TIMELOG_COLOR_SCALE.colors(undefined)

        let lastLat = null;
        let lastLong = null;

        super({
            id: "Measurements",
            data: {
                ...convertToGeoJSONPoints(points),
                
            },
            pointRadiusUnits: 'pixels',
            getPointRadius: 5,
            pickable: false
        })
    }
}

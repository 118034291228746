// Define the GeoJSON types (you could also install and use @types/geojson)
export interface GeoJSONFeature {
    type: "Feature";
    geometry: {
      type: string;
      coordinates: any[]; // [longitude, latitude]
    };
    properties: Record<string, any>;
  }
  
export interface GeoJSONFeatureCollection {
    type: "FeatureCollection";
    features: GeoJSONFeature[];
  }

export function convertToGeoJSONPoints(coords: number[][]): GeoJSONFeatureCollection {
    const features: GeoJSONFeature[] = coords.map((coord) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coord, // If your coords are [lat, lon] swap here: [coord[1], coord[0]]
      },
      properties: {}, // Add any properties you need here
    }));
  
    return {
      type: "FeatureCollection",
      features,
    };
  }

export function convertToGeoJSONLines(points: number[][]): GeoJSONFeatureCollection {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: points, // assumes points are in [longitude, latitude] order
        },
        properties: {},
      },
    ],
  }
}
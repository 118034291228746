import { GeoJsonLayer } from "@deck.gl/layers";
import { convertToGeoJSONLines } from "../utils/PointGeoJSONUtils";

export default class MeasurementLinesLayer extends GeoJsonLayer<unknown> {
  constructor(points: number[][]) {
    super({
      id: "MeasurementLines",
      data: convertToGeoJSONLines(points),
      stroked: true,        // Draw stroke for lines
      filled: false,        // No fill needed for a line
      lineWidthMinPixels: 2, // Adjust the line width as needed
      getLineColor: [255, 0, 0], // Red color for the line (RGB)
      pickable: false,
    });
  }
}
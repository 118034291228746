import React, { useState } from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import VisibilityIcon from '@mui/icons-material/Visibility'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import Button from "@mui/material/Button"
import PlaceIcon from '@mui/icons-material/Place';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export interface MapMenuProps {
    Latitude: number,
    Longitude: number,
    Distance: number,
    PointCount: number,
    OnStatusChange: (status:number)=>void,
    OnResetMeasurements: ()=>void

}

export function MapMenu( {Latitude, Longitude, Distance, PointCount, OnStatusChange, OnResetMeasurements}: MapMenuProps){

    const [_status,setStatus] = useState<number>(1);

    function UpdateStatus(status){
        setStatus(status);
        OnStatusChange(status);
    }

    function GetText(){
        switch(_status){
            case 1:
                return "";
            case 2: 
                return (
                <table>
                    <th><td>Distance</td></th>
                    <tr><td>Distance:</td><td>{Distance.toFixed(2)}m</td></tr>
                    <tr><td>Points:</td><td>{PointCount}</td></tr>
                    <tr><td><Button onClick={()=>{OnResetMeasurements()}}><RestartAltIcon/></Button></td></tr>
                </table>
            );
            case 3:
                if( Latitude != 0 && Longitude != 0){
                    return (
                        <table>
                            <th>
                                <td>Position: </td>
                                <td></td>
                            </th> 
                            <tr><td>Longitude</td><td>{Longitude.toFixed(5)}</td> </tr>
                            <tr><td>Latitude</td><td>{Latitude.toFixed(5)}</td> </tr>
                        </table>
                        )        
                } else {
                    return (
                            <p>Position: -/- </p>
                        )        
                }
        }
    }

    return (
        <Box 
                    sx= {{
                        position:'absolute',
                        right: '10px',
                        top: '10px',
                        width: '200px',
                        height: _status==1?'50px': _status==2 ? '180px': '140px',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        zIndex:17
                    }}       
                    >
            <Button sx={{backgroundColor: _status==1? '#DDDDDD' : '#FFFFFF'}} onClick={()=>{UpdateStatus(1);}}>
                <img src="cursor.svg" width="32px" height="32px"/>
            </Button>
            <Button sx={{backgroundColor: _status==2? '#DDDDDD' : '#FFFFFF'}} onClick={()=>{UpdateStatus(2);}}>
                <img src="distance.png" width="32px" height="32px"/>
            </Button>
            <Button sx={{backgroundColor: _status==3? '#DDDDDD' : '#FFFFFF'}} onClick={()=>{UpdateStatus(3);}}>
                <PlaceIcon/>
            </Button>
            <br></br>
            {GetText()}
            
        </Box>
    )
}